















































































































































@import "@/assets/css/project-variables";
.smz-home-settings{

  .card-header{
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 18px;
  }
  .card-sub-header{
    margin: 50px 0 10px 0;
    font-weight: bold;
    font-size: 16px;
  }
  .card-sub-name{
    margin: 20px 0 5px 0;
  }
}
